/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { IconClose } from '~/assets/Icons';
import { Button, Input, Modal, Select } from '~/components';
import { CloseButton, ContainerInput, Title } from './styles';
import sectorService from '~/services/sectorService';
import { useFilterContext } from '~/hooks/useFilter';
import { currentYearString } from '~/utils/dates';

interface IProps {
  onClickClose: () => void;
  onChange: (e: any) => void;
}

const ModalAddFilters: React.FC<IProps> = ({
  onClickClose,
  onChange,
}: IProps) => {
  const { setFilterSearch, filterSearch } = useFilterContext();
  const [initialDate, setInitialDate] = useState(
    filterSearch.initialDate || `${currentYearString}-01-01`,
  );
  const [finalDate, setFinalDate] = useState(
    filterSearch.finalDate || `${currentYearString}-12-31`,
  );
  const [currentSectorId, setCurrentSectorId] = useState<any>(undefined);
  const [hasComments, setHasComments] = useState(
    filterSearch.hasComments || false,
  );
  const [isOutOfDate, setIsOutOfDate] = useState(
    filterSearch.isOutOfDate || false,
  );
  const [loading, setLoading] = useState(true);
  const [listSector, setListSector] = useState<any>([]);
  const [currentSectorName, setCurrentSectorName] = useState<any>('');
  const erro = '';

  console.log(currentSectorName);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const response = await sectorService.listSector({ limit: 200, page: 1 });
      setListSector(response.result);
      console.log(filterSearch?.currentSectorId);
      if (filterSearch?.currentSectorId) {
        const data = response.result.filter((e: any) => {
          return e.id === filterSearch.currentSectorId;
        });
        setCurrentSectorName(data[0].name);
      }
      setLoading(false);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterSearch({
      initialDate,
      finalDate,
      currentSectorId,
      hasComments,
      isOutOfDate,
    });
  }, [initialDate, finalDate, currentSectorId, hasComments, isOutOfDate]);

  return (
    <Modal>
      <>
        <CloseButton onClick={() => onClickClose()}>
          <IconClose />
        </CloseButton>
        <Title>Filtrar</Title>
        <ContainerInput>
          <Input
            type="date"
            value={initialDate}
            onChange={e => setInitialDate(e.target.value)}
            label="Início"
          />
          <Input
            type="date"
            value={finalDate}
            onChange={e => setFinalDate(e.target.value)}
            label="Fim"
          />
        </ContainerInput>
        <Select
          label={loading ? 'Carregando...' : 'Setor'}
          erro={erro}
          lista={listSector}
          currentValue={currentSectorName}
          onClickList={item => {
            setCurrentSectorName(item.name);
            setCurrentSectorId(item.id);
          }}
        />
        <div style={{ height: '20px' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            onChange={e => setHasComments(e.target.checked)}
            checked={hasComments}
          />
          <div style={{ width: '10px' }} />
          <b>Com justificativa</b>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            onChange={e => setIsOutOfDate(e.target.checked)}
            checked={isOutOfDate}
          />
          <div style={{ width: '10px' }} />
          <b>Data diferente</b>
        </div>
        <div style={{ height: '30px' }} />
        <Button
          onClick={() => {
            onChange({
              initialDate,
              finalDate,
              currentSectorId,
              hasComments,
              isOutOfDate,
            });
            onClickClose();
          }}
        >
          Filtrar
        </Button>
        <div style={{ height: '10px' }} />
        {/* <Button
          styles={1}
          onClick={() => {
            onChange({
              initialDate,
              finalDate,
              currentSectorId,
              hasComments,
              isOutOfDate,
            });
            onClickClose();
          }}
        >
          Limpar filtros
        </Button> */}
      </>
    </Modal>
  );
};

export default ModalAddFilters;
