import React, { useState } from 'react';
import { IconClose } from '~/assets/Icons';
import { Button, Modal, Select } from '~/components';
import reportsService from '~/services/reportsService';
import { Content, TitleModal } from './styles';
import { currentYearString, generateYearArray } from '~/utils/dates';

interface IProps {
  tabs: {
    church: boolean;
    manager: boolean;
  };
  onClose: () => void;
}

const ModalExport: React.FC<IProps> = ({ tabs, onClose }: IProps) => {
  const years = generateYearArray();
  const [yearSelect, setYearSelect] = useState<string>(currentYearString);

  const exportExcel = () => {
    if (tabs.church) {
      reportsService.exportExcelReportChurch({ year: `${yearSelect}` });
      return onClose();
    }
    reportsService.exportExcelReportLeader({ year: `${yearSelect}` });
    onClose();
    return null;
  };

  return (
    <>
      <Modal width="400px">
        <>
          <TitleModal>
            <button type="button" onClick={onClose}>
              <IconClose />
            </button>
          </TitleModal>
          <Content>
            <Select
              lista={years}
              currentValue={yearSelect}
              erro=""
              label="Selecione o ano:"
              onClickList={e => setYearSelect(e.name)}
            />
            <span>
              <Button onClick={() => exportExcel()}>Exportar</Button>
            </span>
          </Content>
        </>
      </Modal>
    </>
  );
};

export default ModalExport;
