import React, { useEffect, useState } from 'react';
import { IconClose } from '~/assets/Icons';
import { Button, Loading, Modal, Select } from '~/components';
import sectorService from '~/services/sectorService';
import { Content, TitleModal } from './styles';
import { generateYearArray } from '~/utils/dates';

interface IProps {
  data: { name: string; id: number };
  setData: (e: { name: string; id: number }) => void;
  setYear: (e: string) => void;
  year: string;
  onClose: () => void;
}

const ModalFilter: React.FC<IProps> = ({
  data,
  onClose,
  setData,
  year,
  setYear,
}: IProps) => {
  const years = generateYearArray();

  const [listSector, setListSector] = useState([]);
  const [erroSelect, setErroSelect] = useState('');
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(data);
  const [yearSelect, setYearSelect] = useState(year);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const response = await sectorService.listSector({ limit: 200, page: 1 });
      setListSector(response.result);
      setLoading(false);
    };
    load();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Modal width="400px">
        <>
          <TitleModal>
            <button type="button" onClick={onClose}>
              <IconClose />
            </button>
          </TitleModal>
          <Content>
            <b>Filtrar por:</b>
            <Select
              lista={years}
              currentValue={yearSelect}
              erro=""
              label="Ano:"
              onClickList={e => setYearSelect(e.name)}
            />
            <Select
              label="Setor:"
              lista={listSector}
              onClickList={e => {
                setErroSelect('');
                setValue({ name: e.name, id: e.id });
              }}
              currentValue={value?.name}
              erro={erroSelect}
            />
            <span>
              <Button
                onClick={() => {
                  if (value.id !== 0) {
                    setData(value);
                  }
                  setYear(yearSelect);
                  onClose();
                }}
              >
                Filtrar
              </Button>
            </span>
            {data.id !== 0 && (
              <span>
                <Button
                  styles={1}
                  onClick={() => {
                    setData({ name: '', id: 0 });
                    setValue({ name: '', id: 0 });
                    onClose();
                  }}
                >
                  Limpar Filtro
                </Button>
              </span>
            )}
          </Content>
        </>
      </Modal>
    </>
  );
};

export default ModalFilter;
