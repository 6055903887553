/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IoEyeSharp } from 'react-icons/io5';
import { BsCalendarX } from 'react-icons/bs';
// import { BsGraphUp, BsGraphDown } from 'react-icons/bs';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { useHistory } from 'react-router-dom';
import {
  Page,
  TablePage,
  Loading,
  ModalDefault,
  ModalDelete,
  Button,
  Input,
} from '~/components';
import { Content, Actions, Header, ExcelButton, FilterButton } from './styles';
import ModalDetails from './components/ModalDetails';
import BiblicalSchool from '~/services/BiblicalSchool';
import ReportsService from '~/services/reportsService';
import { BtEdit, BtTrash } from './Church/styles';
import { IconDelete, IconEdit, IconExcel, IconFilter } from '~/assets/Icons';
import { usePageContext } from '~/hooks';
import { Title } from '~/components/PageHeader/styles';
import ModalAddFilters from './components/modalAddFilters';
import { useFilterContext } from '~/hooks/useFilter';
import { currentYearString } from '~/utils/dates';

const EBD: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { filterSearch, setInputSearch, inputSearch } = useFilterContext();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [pages, setPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [isVisibleModalInfo, setIsVisibleModalInfo] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalFilter, setIsVisibleModalFilter] = useState(false);
  const [menssageModalErro] = useState('');
  const [handleIdInfo, setHandleIdInfo] = useState([]);
  const [input, setInput] = useState(inputSearch);
  const [time, setTime] = useState<any>(null);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);
  const [initialDate, setInitialDate] = useState(
    filterSearch?.initialDate || `${currentYearString}-01-01`,
  );
  const [finalDate, setFinalDate] = useState(
    filterSearch?.finalDate || `${currentYearString}-12-31`,
  );
  const [currentSectorId, setCurrentSectorId] = useState<any>(
    filterSearch?.currentSectorId || undefined,
  );
  const [hasComments, setHasComments] = useState(
    filterSearch?.hasComments || false,
  );
  const [isOutOfDate, setIsOutOfDate] = useState(
    filterSearch?.isOutOfDate || false,
  );
  const history = useHistory();
  const { setHistoyRoutes, histoyRoutes } = usePageContext();

  const loadClasses = async ({
    page,
    church,
  }: {
    page: number;
    church?: string;
  }) => {
    setLoading(true);
    const res = await BiblicalSchool.listFrequency({
      limit,
      page,
      church,
      finalDate,
      hasComments,
      initialDate,
      isOutOfDate,
      sector: currentSectorId,
    });
    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  const columns = [
    {
      title: 'Igreja',
      dataIndex: 'church',
      key: 'church',
      render: (e: any) => e.name,
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (e: any) =>
        `${e && format(new Date(e), "dd 'de' MMMM", { locale: pt })}`,
    },
    {
      title: 'F. do dirigente',
      dataIndex: 'pastor_present',
      key: 'pastor_present',
      render: (e: boolean) => (e ? 'Presente' : 'Ausente'),
    },
    {
      title: 'F. da igreja',
      key: 'frequency church',
      render: (e: any) => {
        return `${e.totalPresence} alunos (${e.presencePercentage || '-'}%)`;
      },
    },
    // {
    //   title: 'Variação',
    //   dataIndex: 'increasedPresence',
    //   key: 'increasedPresence',
    //   render: (e: number) =>
    //     e > 0 ? (
    //       <Positive>
    //         {e}% <BsGraphUp />
    //       </Positive>
    //     ) : (
    //       <Negative>
    //         {e}% <BsGraphDown />
    //       </Negative>
    //     ),
    // },
    {
      // title: 'Ver mais',
      key: 'frequencyClasse',
      width: '60px',
      render: (e: any) => (
        <Actions>
          <>
            {e.is_out_of_date && <BsCalendarX />}
            <button
              type="button"
              onClick={() => {
                setHandleIdInfo(e);
                setIsVisibleModalInfo(true);
              }}
            >
              <IoEyeSharp />
            </button>
            <BtTrash
              onClick={() => {
                setHandleIdDelete(e.id);
                setIsVisibleModalDelete(true);
              }}
            >
              <IconDelete />
            </BtTrash>
            <BtEdit onClick={() => history.push(`/frequencia-ebd/${e.id}`)}>
              <IconEdit />
            </BtEdit>
          </>
        </Actions>
      ),
    },
  ];

  const deleteReports = async () => {
    setLoading(true);
    await BiblicalSchool.deleteFrequency(handleIdDelete);
    setIsVisibleModalDelete(false);
    setHandleIdDelete(0);
    setPage(1);
    await loadClasses({ page: 1, church: input });
  };

  const doSearch = async (val: string) => {
    setHistoyRoutes({
      route: histoyRoutes.route,
      state: val,
    });
    setPage(1);
    loadClasses({ page: 1, church: val });
  };

  useEffect(() => {
    loadClasses({ page, church: input });
  }, [
    page,
    limit,
    initialDate,
    finalDate,
    currentSectorId,
    hasComments,
    isOutOfDate,
  ]);

  return (
    <>
      {isVisibleModalFilter && (
        <ModalAddFilters
          onChange={e => {
            setInitialDate(e.initialDate);
            setCurrentSectorId(e.currentSectorId);
            setFinalDate(e.finalDate);
            setHasComments(e.hasComments);
            setIsOutOfDate(e.isOutOfDate);
          }}
          onClickClose={() => setIsVisibleModalFilter(false)}
        />
      )}
      {isVisibleModalDelete && (
        <ModalDelete
          text="relatório"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteReports()}
        />
      )}
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          {menssageModalErro}
        </ModalDefault>
      )}
      {isVisibleModalInfo && (
        <ModalDetails
          onClick={() => setIsVisibleModalInfo(false)}
          list={handleIdInfo}
        />
      )}

      <Page>
        <Header>
          <Title>Relatório EBD</Title>
          <div style={{ width: '30%' }}>
            <Input
              placeholder="Pesquisar"
              value={input}
              onChange={(e: any) => {
                setInputSearch(e.target.value);
                setInput(e.target.value);
                if (time !== null) {
                  clearTimeout(time);
                }
                setTime(
                  setTimeout(() => {
                    doSearch(e.target.value);
                  }, 1000),
                );
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={() => history.push('/frequencia-ebd/criar')}>
              Criar Frequência
            </Button>
            <ExcelButton onClick={() => ReportsService.exportExcel()}>
              <IconExcel />
            </ExcelButton>
            <FilterButton onClick={() => setIsVisibleModalFilter(true)}>
              <IconFilter />
            </FilterButton>
          </div>
        </Header>
        <Content>
          {loading ? (
            <Loading />
          ) : (
            <TablePage
              columns={columns}
              dataSource={dataSource}
              pagination={{
                position: ['topRight', 'bottomRight'],
                showSizeChanger: true,
                current: page,
                pageSize: limit,
                total: pages * limit,
                onChange: e => {
                  setPage(e);
                },
                onShowSizeChange: (page, size) => {
                  if (page !== size) setLimit(size);
                },
              }}
              rowKey="id"
            />
          )}
        </Content>
      </Page>
    </>
  );
};

export default EBD;
