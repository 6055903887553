export function generateYearArray(
  endYear = 2022,
): { name: string; id: number }[] {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= endYear; year -= 1) {
    years.push({ name: year.toString(), id: currentYear - year + 1 });
  }

  return years;
}

export const lastYear = new Date().getFullYear() - 1;
export const currentYear = new Date().getFullYear();
export const currentYearString = String(currentYear);
